import { FiExternalLink } from "react-icons/fi";
import JavaScript from "../../img/logos/javascript.svg";
import TypeScript from "../../img/logos/typescript-icon.svg";
import NodeJS from "../../img/logos/nodejs-icon.svg";
import React from "../../img/logos/react.svg";
import Redux from "../../img/logos/redux.svg";
import ExpressJS from "../../img/logos/express.svg";
import Koa from "../../img/logos/koa.svg";
import PostgreSQL from "../../img/logos/postgresql.svg";
import MySQL from "../../img/logos/mysql-icon.svg";
import MongoDB from "../../img/logos/mongodb-icon.svg";
import TailwindCSS from "../../img/logos/tailwindcss-icon.svg";
import Jest from "../../img/logos/jest.svg";
import Cypress from "../../img/logos/cypress-icon.svg";
import GitHub from "../../img/logos/github-icon.svg";
import Vite from "../../img/logos/vite-logo.svg";

import scUP1 from "../../img/screens/screenshot-up-1.jpg";
import scUP2 from "../../img/screens/screenshot-up-2.jpg";
import scUP3 from "../../img/screens/screenshot-up-3.jpg";
import scJam from "../../img/screens/screenshot-jam.jpg";
import scLama1 from "../../img/screens/screenshot-lama-1.jpg";
import scLama2 from "../../img/screens/screenshot-lama-2.jpg";
import scLama21 from "../../img/screens/screenshot-lama2-1.png";
import scGroupVenture from "../../img/screens/screenshot-groupventure.webp";

import logoUP from "../../img/logos/logo-up.png";
import logoJam from "../../img/logos/logo-jam.png";
import logoLama from "../../img/logos/logo-lama.png";
import logoGroupVenture from "../../img/logos/logo-groupventure.jpg";
import logoRJA from "../../img/logos/logo-rja.jpg";

const technologies = {
  TypeScript: TypeScript,
  JavaScript: JavaScript,
  NodeJS: NodeJS,
  React: React,
  Redux: Redux,
  ExpressJS: ExpressJS,
  Koa: Koa,
  PostgreSQL: PostgreSQL,
  MySQL: MySQL,
  MongoDB: MongoDB,
  TailwindCSS: TailwindCSS,
  Jest: Jest,
  Cypress: Cypress,
  Vite: Vite,
};

const screenshots = {
  scJam: scJam,
  scGroupVenture: scGroupVenture,
};

const logos = {
  logoUP: logoUP,
  logoJam: logoJam,
  logoLama: logoLama,
  logoGroupVenture: logoGroupVenture,
  logoRJA: logoRJA,
};

function WorkCard({ job, className }) {
  return (
    <div
      className={`mt-10 relative flex flex-col justify-between w-full max-w-[600px] h-auto bg-slate-300 p-4 pt-2 border-t-4 border-t-sky-400 rounded-b-xl shadow-2xl hover:shadow-[0_20px_25px_-5px_rgba(0,0,0,0.5)] transition-all duration-1000 dark:bg-sky-300/30 ${className} dark:shadow-[0_15px_30px_10px_rgba(0,0,0,0.8)]`}
    >
      {job.weblink === "" ? (
        ""
      ) : (
        <a
          className="absolute right-4 top-4 cursor-pointer"
          title="Website link"
          href={job.weblink}
          target="_blank"
        >
          <FiExternalLink className="dark:text-white" />
        </a>
      )}
      {job.ghlink === "" ? (
        ""
      ) : (
        <a
          className={`absolute top-4 ${
            job.weblink === "" ? "right-4" : "right-12"
          } cursor-pointer`}
          href={job.ghlink}
          target="_blank"
        >
          <img
            src={GitHub}
            title="GitHub repository"
            className="h-4 w-4 dark:invert"
          />
        </a>
      )}

      <h2 className="absolute -translate-x-4 -translate-y-9 text-sm text-white/75 font-thin uppercase ">
        {job.period}
      </h2>
      <div className="hidden md:block absolute -translate-x-20 -translate-y-3">
        <div className="w-20 h-1 bg-sky-400" />
        <div className="overflow-none flex w-14 h-14 rounded-full border-4 bg-[#05232f] border-sky-400 -translate-x-6 -translate-y-7">
          <img
            src={logos[job.logo]}
            alt="logo"
            className={`mx-auto my-auto w-10 ${
              job.logo === "logoLama" ? "invert w-9" : "null"
            } ${
              job.logo === "logoGroupVenture"
                ? "invert w-12 rounded-full"
                : "null"
            }`}
          />
        </div>
      </div>

      <div className="">
        <div className="flex flex-col md:flex-row items-start justify-start gap-0 md:gap-2">
          <h1 className="text-xl dark:text-white font-semibold">{job.title}</h1>
          <h2 className="flex gap-2 text-lg dark:text-white/50 font-thin">
            <span className="hidden md:block">- </span>
            {job.position}
          </h2>
        </div>

        {job.screenshot === "" ? (
          <div className="h-2" />
        ) : job.screenshot === "scUP" ? (
          <div className="flex gap-2 overflow-scroll no-scrollbar">
            <img
              src={scUP2}
              alt="screenshot-up"
              className="bg-slate-700 my-2 w-full h-[300px] object-cover object-top shadow-md"
            />
            <img
              src={scUP1}
              alt="screenshot-up"
              className="bg-slate-700 my-2 w-full h-[300px] object-cover object-center  shadow-md"
            />
            <img
              src={scUP3}
              alt="screenshot-up"
              className="bg-slate-700 my-2 w-full h-[300px] object-cover object-bottom shadow-md"
            />
          </div>
        ) : job.screenshot === "scLama" ? (
          <div className="flex gap-2 overflow-scroll no-scrollbar">
            <img
              src={scLama1}
              alt="screenshot-lama"
              className="bg-slate-700 my-2 w-full h-[150px] object-cover object-top shadow-md"
            />
            <img
              src={scLama21}
              alt="screenshot-lama2"
              className="bg-slate-700 my-2 w-2/3 h-[150px] object-cover object-top shadow-md"
            />
          </div>
        ) : (
          <img
            src={screenshots[job.screenshot]}
            alt="screenshot-app"
            className="bg-slate-700 my-2 w-full h-[150px] object-cover object-top shadow-md"
          />
        )}
        <p className="dark:text-white text-sm">{job.description}</p>
        <ul className="dark:text-white text-sm list-disc ml-4 mt-2 space-y-1">
          {job.bulletPoints.map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ul>
      </div>

      <div className="flex flex-row justify-center gap-3 w-full mt-4">
        {job.stack.map((item, index) => (
          <img
            key={index}
            src={technologies[item]}
            title={item}
            className="w-8 h-8"
          />
        ))}
      </div>
    </div>
  );
}

export default WorkCard;
