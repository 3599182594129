import WorkCard from "./WorkCard";
import WorkCardArq from "./WorkCardArq";
import { RiArrowDownSLine } from "react-icons/ri";
import useSound from "use-sound";
import bubbleSfx from "../../sfx/bubble-sound.mp3";

const jobs = [
  {
    period: "Q3.2023 - Today",
    title: "Universal Plastic",
    subtitle: "",
    position: "Full Stack Developer",
    screenshot: "scUP",
    logo: "logoUP",
    description:
      "Universal Plastic is an innovative app that merges ocean conservation with digital technology. It transforms plastic waste collections into valuable digital assets, empowering its users to track their impact and earn monetary compensation.",
    bulletPoints: [
      "Developing and maintaining both the front and back ends of the web app",
      "Planning, creating, documenting and maintaining the API requests",
      "Implementing a variety of features including Google and Apple authentication, internationalization, referral program, subscriptions, rewards, badges...",
      "Collaborating closely with cross-functional teams to deliver high-quality solutions efficiently",
      "Showing agility by swiftly resolving issues and integrating new features in response to evolving project requirements",
      "MERN stack:",
    ],
    stack: [
      "JavaScript",
      "NodeJS",
      "ExpressJS",
      "MongoDB",
      "React",
      "Vite",
      "TailwindCSS",
    ],
    weblink: "https://app.universalplastic.io/",
    ghlink: "",
  },
  {
    title: "JAM",
    period: "Q2.2023",
    subtitle: "",
    position: "Full Stack Developer",
    screenshot: "scJam",
    logo: "logoJam",
    description:
      "JAM is an event planning app aimed to make easier get together with friends, decide on the plans and split the costs. It includes dedicated chats among your groups.",
    bulletPoints: [
      "Leaded the back end with the main focus on an early and rapid deployment",
      "Supervised and collaborated in the front end to develop the UI/UX design and logic",
      "Articulated over 20 endpoints for the API and secured seamless connections",
      "Added backend testing with Jest and Supertest",
      "Enabled JSON Web Token authentication",
    ],
    stack: [
      "TypeScript",
      "NodeJS",
      "ExpressJS",
      "PostgreSQL",
      "React",
      "Redux",
    ],
    weblink: "",
    ghlink: "",
    // weblink: "https://jaminprogress.vercel.app/",
    // ghlink: "https://github.com/Luigi-Faldetta/JAM-Join-Arrange-Manage",
  },
  {
    title: "GroupVenture",
    period: "Q2.2023",
    subtitle: "",
    position: "Software Engineer",
    screenshot: "",
    // screenshot: "scGroupVenture",
    logo: "logoGroupVenture",
    description:
      "GroupVenture is a versatile and user-friendly app designed to connect individuals with shared interests and facilitate the organization of various activities.",
    bulletPoints: [
      "Worked in a small team refactoring and debugging the application",
      "Implemented TypeScript in both front and back ends",
      "Improved the performance of the app and enhanced the UI and UX design",
      "Added back end and E2E tests using Jest and Cypress",
    ],
    stack: [
      "TypeScript",
      //  "PostgreSQL",
      "React",
      "Jest",
      "Cypress",
    ],
    weblink: "",
    ghlink: "",
    // ghlink: "https://github.com/alepemu/GroupVenture",
  },
  {
    title: "LAMA*",
    period: "Q1.2023",
    subtitle: "",
    position: "Solo project",
    screenshot: "scLama",
    logo: "logoLama",
    description:
      "LAMA is an app that helps you keep track of your reminders and checklists. It can send you personalised emails and includes an AI chatbot ready to answer any query.",
    bulletPoints: [
      "Designed and developed the entire app",
      "Added email and chat functionalities using Nodemailer and OpenAI API",
      "*LAMA2 is a refactored version of LAMA with a new UI/UX design and improved technologies and functionalities like drag and drop, AI note generation and more",
    ],
    stack: ["TypeScript", "NodeJS", "ExpressJS", "MySQL", "React", "Redux"],
    // weblink: "https://lama-reminder.netlify.app/",
    weblink: "https://lama2-delta.vercel.app/",
    ghlink: "https://github.com/alepemu/lama2",
  },
  {
    title: "Rolfe Judd Architecture",
    period: "2017 - 2022",
    subtitle: "",
    position: "Architect",
    screenshot: "",
    logo: "logoRJA",
    description: "",
    bulletPoints: [
      "Worked on a variety of residential, office and mixed use schemes",
      "Gained expertise working with multidisciplinary teams",
      "Improved problem solving abilities and analytical thinking",
      "Tech stack: Adobe Creative Suite, 3D SketchUp, Autodesk Software...",
    ],
    stack: [],
    weblink:
      // "https://issuu.com/geoalx/docs/apm_pf_2024-compressed-pages-deleted",
      "portfolio",
    ghlink: "",
  },
];

function Work() {
  const [play] = useSound(bubbleSfx);

  return (
    <>
      <div
        id="work"
        className="relative flex flex-col justify-center items-center min-h-screen w-full pt-8 pb-16 sm:pt-16 sm:pb-20 border-t border-t-white/50 border-b border-b-white/50"
        style={{
          backgroundColor: "#292524",
          opacity: "1",
          background:
            "radial-gradient(circle, transparent 20%, #292524 20%, #292524 80%, transparent 80%, transparent), radial-gradient(circle, transparent 20%, #292524 20%, #292524 80%, transparent 80%, transparent) 12.5px 12.5px, linear-gradient(#1c1917 1px, transparent 1px) 0 -0.5px, linear-gradient(90deg, #1c1917 1px, #292524 1px) -0.5px 0",
          backgroundSize: "25px 25px, 25px 25px, 12.5px 12.5px, 12.5px 12.5px",
        }}
      >
        <div
          className="absolute inset-0 z-1"
          style={{
            background:
              "linear-gradient(to right, rgba(0,0,0,0), rgba(28,25,23,1))",
          }}
        />
        <div className="absolute bottom-0 z-2">
          <a href="#about">
            <RiArrowDownSLine
              className="h-8 w-8 fill-slate-300"
              onClick={() => play()}
            />
          </a>
        </div>

        <div className="flex flex-col w-full sm:w-4/5 px-6 sm:px-0 z-2">
          <div className="flex flex-col items-center">
            <div className="absolute hidden md:block -translate-x-[330px] translate-y-10 w-1 h-[calc(100%-820px)] bg-sky-400" />
            <div className="absolute hidden md:block -translate-x-[330px] translate-y-[calc(100%+1150px)] w-1 h-[450px] bg-gradient-to-b from-sky-400 to-amber-400" />
            <div className="absolute hidden md:block -translate-x-[330px] translate-y-[calc(100%+1835px)] w-1 h-[215px] bg-gradient-to-b from-amber-400 to-amber-400/0" />
            <div className="flex flex-col w-full items-center gap-6 md:translate-x-[30px]">
              <WorkCard job={jobs[0]} className="" />
              <WorkCard job={jobs[1]} className="" />
              <WorkCard job={jobs[2]} className="" />
              <WorkCard job={jobs[3]} className="dark:bg-stone-300/30" />
              <WorkCardArq job={jobs[4]} className="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Work;
