export function Bookmark({ id, before, start, end }) {
  const beforeHeight = before ? { height: `${before}00vh` } : null;
  const afterHeight = end - start ? { height: `${end - start}00vh` } : null;
  return (
    <div>
      {beforeHeight && <div style={beforeHeight} className="border-l-0 w-16" />}
      <div className="border-l-0 h-screen w-16 sticky top-0 overflow-hidden">
        <h1 className="text-4xl text-center font-bold whitespace-nowrap opacity-10 pt-2">
          {id}
        </h1>
      </div>
      {afterHeight && <div style={afterHeight} />}
    </div>
  );
}
