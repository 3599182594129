import { useState, useRef, useEffect } from "react";

function AboutExperience() {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [screenWidth, setScreenWidth] = useState(getCurrentWidth());
  const experienceContainer = useRef(null);

  function getCurrentWidth() {
    return window.innerWidth;
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenWidth(getCurrentWidth());
    };
    window.addEventListener("resize", updateDimension);
  }, [screenWidth]);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    });
    observer.observe(experienceContainer.current);
    return () => observer.disconnect();
  }, [isIntersecting]);

  useEffect(() => {
    const logos =
      experienceContainer.current.querySelector("#experience").classList;
    if (screenWidth >= 400) {
      isIntersecting
        ? logos.remove("opacity-0", "blur", "translate-x-[150%]")
        : logos.add("opacity-0", "blur", "translate-x-[150%]");
    }
  }, [isIntersecting]);

  return (
    <div ref={experienceContainer} className="w-4/5 lg:w-2/5 mt-8 lg:mt-0">
      <div id="experience" className="transition-all ease-in-out duration-500">
        <h2 className="font-bold text-2xl mb-4">EXPERIENCE</h2>
        <p className="mb-4">
          My background in Architecture has equipped me with a unique blend of
          skills and perspectives that is highly transferable to the field of
          software development. I had the opportunity to work in
          multidisciplinary teams and deliver successful projects that require
          continuous problem solving and analytical thinking skills.
        </p>
        <p className="mb-4">
          For the last 2-3 years, I have been working on different projects that
          have boosted my knowledge and skills as a software developer.
        </p>
      </div>
    </div>
  );
}

export default AboutExperience;
