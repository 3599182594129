import { RiArrowDownSLine } from "react-icons/ri";
import AboutMe from './AboutMe';
import AboutExperience from './AboutExperience';
import AboutLogos from './AboutLogos';
import useSound from 'use-sound';
import bubbleSfx from '../../sfx/bubble-sound.mp3';

function About() {
  const [play] = useSound(bubbleSfx);

  return (
    <div id='about' className="relative flex flex-col justify-evenly items-center min-h-screen w-full dark:text-white font-thin pt-16 pb-20 lg:pt-0 lg:pb-0 transition-all ease-in-out duration-100">

      <div className='absolute bottom-0 h-8'>
        <a href='#contact'>
          <RiArrowDownSLine className='h-8 w-8 fill-slate-700 dark:fill-slate-300' onClick={() => play()} />
        </a>
      </div>

      <div className="flex flex-col lg:flex-row justify-evenly items-center overflow-hidden">
        <AboutMe />
        <AboutExperience />
      </div>

      <AboutLogos />

    </div>
  );
}

export default About;
