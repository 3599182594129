import { memo, useRef } from "react";
import { motion, useSpring, useTransform } from "framer-motion";

function Bubble({ mouseY, data, active, areHovered }) {
  let ref = useRef(null);

  let distance = useTransform(mouseY, (val) => {
    let bounds = ref.current?.getBoundingClientRect() ?? { y: 0, height: 0 };
    return val - bounds.y - bounds.height / 2;
  });

  let widthSync = useTransform(distance, [-150, 0, 150], [40, 120, 40]);
  let width = useSpring(widthSync, {
    mass: 0.1,
    stiffness: 150,
    damping: 12,
  });

  return (
    <div className="flex items-center gap-2 group ">
      <motion.a
        ref={ref}
        style={{
          width,
          opacity: active || areHovered ? 1 : 0.5,
          transition: "opacity 0.2s ease-in-out",
        }}
        href={`#${data.id}`}
        className="shrink-0 aspect-square w-10 rounded-full cursor-pointer mx-auto flex justify-center overflow-hidden z-10"
      >
        <img
          src={require(`../../img/portfolio/mini/${data.id}mini.webp`)}
          alt={data.id}
          className="object-cover"
        />
      </motion.a>
      <div className="pointer-events-none -translate-x-5 group-hover:opacity-100 opacity-0 transition-all duration-500 bg-white/70 pl-5 pr-2 rounded-lg">
        <p className="whitespace-nowrap text-xl font-semibold text-black/75">
          {data.title}
        </p>
      </div>
    </div>
  );
}

export default memo(Bubble);
