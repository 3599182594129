import { memo, useState } from "react";
import { motion, useMotionValue } from "framer-motion";

import portfolioBookmarks from "../../assets/pf-bookmarks.json";
import Bubble from "./Bubble";

function Dock({ section }) {
  const [isHovered, setIsHovered] = useState(false);
  let mouseY = useMotionValue(Infinity);

  return (
    <div
      className="fixed hidden sm:flex flex-col justify-center h-screen bg-red-400/0 border-r-0 z-100"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <motion.div
        onMouseMove={(e) => mouseY.set(e.clientY)}
        onMouseLeave={() => mouseY.set(Infinity)}
        className="flex flex-col w-16 gap-2 py-4 pl-3 z-50"
        initial={{ opacity: 0 }}
        animate={{ opacity: 100 }}
        transition={{ ease: "easeOut", duration: 2 }}
      >
        {portfolioBookmarks.slice(0, -1).map((data) => (
          <Bubble
            mouseY={mouseY}
            key={data.id}
            data={data}
            active={data.id === section}
            areHovered={isHovered}
          />
        ))}
      </motion.div>
    </div>
  );
}

export default memo(Dock);
