import { BiSolidDownload } from "react-icons/bi";
import { RiArrowDownSLine } from "react-icons/ri";
import { FiExternalLink } from "react-icons/fi";
import useSound from "use-sound";
import bubbleSfx from "../../sfx/bubble-sound.mp3";

function Intro() {
  const [play] = useSound(bubbleSfx);

  return (
    <div
      id="intro"
      className="relative flex flex-col w-4/5 min-h-[550px] items-center h-screen"
    >
      <div className="mx-auto my-auto flex flex-col justify-center items-start dark:text-white -translate-y-10 sm:translate-y-0">
        <h1 className="hidden">Alejandro Penalver Munita</h1>
        <div className="flex flex-col sm:flex-row flex-wrap gap-0 sm:gap-2 mb-4 sm:mb-6">
          <h2 className="text-lg sm:text-xl xl:text-2xl">
            <span className="font-bold text-slate-700 dark:text-sky-400">
              FULL STACK DEVELOPER
            </span>
          </h2>
          <h2 className="text-lg sm:text-xl xl:text-2xl">
            & former{" "}
            <span className="font-bold text-slate-700 dark:text-amber-400">
              ARCHITECT
            </span>
          </h2>
        </div>
        <h2 className="text-3xl font-bold sm:text-5xl xl:text-6xl mb-4 sm:mb-6 md:w-11/12 lg:w-5/6 xl:w-2/3">
          Hi! I am a software developer with a background in Architecture
        </h2>
        <h2 className="text-sm sm:text-md xl:text-base sm:w-1/2 mb-2 sm:mb-4">
          Blending creativity with technical precision to deliver exceptional
          digital experiences.
        </h2>
        <div className="flex flex-col md:flex-row items-start gap-3">
          <div className="flex gap-3">
            <a
              href="#work"
              className="flex flex-row items-center gap-1 bg-sky-400/100 px-2 py-1 rounded-lg hover:bg-sky-400/80 cursor-pointer transition-background duration-200"
            >
              <h2 className="text-black text-sm sm:text-md xl:text-sm sm:p-0.5 font-normal ">
                Work Experience
              </h2>
              <RiArrowDownSLine size={20} color="black" />
            </a>
            <a
              href="/portfolio"
              target="_blank"
              className="flex flex-row items-center gap-1 bg-amber-400/100 px-2 py-1 rounded-lg hover:bg-amber-400/80 cursor-pointer transition-background duration-200"
            >
              <h2 className="text-black text-sm sm:text-md xl:text- sm:p-0.5 font-normal ">
                Portfolio
              </h2>
              <FiExternalLink size={15} color="black" />
            </a>
          </div>
          <a
            href="/cv_alejandro_pm.pdf"
            download
            className="flex flex-row items-center gap-1 bg-white/20 px-2 py-1 rounded-lg hover:bg-white/10 cursor-pointer transition-background duration-200"
          >
            <h2 className="text-sm sm:text-md xl:text-sm sm:p-0.5 font-normal">
              Download CV
            </h2>
            <BiSolidDownload size={20} />
          </a>
        </div>
      </div>
      <div className="absolute bottom-0 h-8">
        <a href="#work" onClick={() => play()}>
          <RiArrowDownSLine className="animate-jump-grow h-8 w-8 fill-slate-700 dark:fill-slate-300" />
        </a>
      </div>
    </div>
  );
}

export default Intro;
