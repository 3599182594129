import useSound from "use-sound";
import clockSfx from "../../sfx/clock-sound.mp3";

function NavBar() {
  const [nav] = useSound(clockSfx);

  return (
    <div className="fixed w-full flex justify-center items-center gap-6 sm:gap-12 lg:gap-24 p-2 z-50 bg-white/80 dark:bg-stone-900 backdrop-blur border-b dark:border-white/50 text-slate-500 dark:text-white/90 transition-all ease-in-out duration-500 z-100">
      <h1 className="hidden">Alejandro Penalver Munita</h1>
      <a
        href="#intro"
        className="hover:text-slate-700 dark:hover:text-white"
        onClick={() => nav()}
      >
        <span className="opacity-50">#</span> intro
      </a>
      <a
        href="#work"
        className="hover:text-slate-700 dark:hover:text-white"
        onClick={() => nav()}
      >
        <span className="opacity-50">#</span> timeline
      </a>
      <a
        href="#about"
        className="hover:text-slate-700 dark:hover:text-white"
        onClick={() => nav()}
      >
        <span className="opacity-50">#</span> about
      </a>
      <a
        href="#contact"
        className="hover:text-slate-700 dark:hover:text-white"
        onClick={() => nav()}
      >
        <span className="opacity-50">#</span> contact
      </a>
    </div>
  );
}

export default NavBar;
