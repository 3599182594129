export default function XLinks() {
  return (
    <div className="absolute -translate-y-full w-screen sm:w-[calc(100%-128px)] h-screen flex justify-center mx-auto">
      <div className="max-w-full aspect-[3000/2121] flex flex-col justify-center">
        <div className="border-0 border-yellow-600/50 w-full aspect-[3000/2121]">
          <div className="relative flex justify-between w-[62.2%] h-[13%] top-[14.2%] left-[3%]">
            <a
              href="https://www.youtube.com/watch?v=6_dFMlzWrC0"
              target="_blank"
              className="relative hover:bg-blue-400/30 w-[23.3%] h-full cursor-pointer transition-all duration-200 linear"
            />
            <a
              href="https://www.youtube.com/watch?v=6mPg1orznoA"
              target="_blank"
              className="relative hover:bg-blue-400/30 w-[23.3%] h-full cursor-pointer transition-all duration-200 linear"
            />
            <a
              href="https://youtu.be/6QBgWv8o4Ro"
              target="_blank"
              className="relative hover:bg-blue-400/30 w-[23.3%] h-full cursor-pointer transition-all duration-200 linear"
            />
            <a
              href="https://youtu.be/JpyBPxFRq44"
              target="_blank"
              className="relative hover:bg-blue-400/30 w-[23.3%] h-full cursor-pointer transition-all duration-200 linear"
            />
          </div>
          <a
            href="https://www.flickr.com/photos/144836705@N04/"
            target="_blank"
            className="relative flex justify-between hover:bg-blue-400/30 w-[15.8%] h-[2%] top-[19.6%] left-[14.5%] cursor-pointer transition-all duration-200 linear"
          />
        </div>
      </div>
    </div>
  );
}
