import { memo } from "react";
import { motion } from "framer-motion";

function Cover() {
  return (
    <div
      id="00"
      className="h-screen flex justify-center snap-start overflow-hidden"
    >
      <motion.div
        className="absolute top-12 flex items-center gap-3"
        initial={{ x: 0, opacity: 0 }}
        animate={{ x: 0, opacity: 100 }}
        transition={{ ease: "easeInOut", duration: 3 }}
      >
        <p className="opacity-50">
          <button
            className="font-bold hover:underline"
            onClick={() => document.documentElement.requestFullscreen()}
          >
            Click here{" "}
          </button>{" "}
          to go full screen
        </p>
      </motion.div>

      <div className="w-full md:w-2/3 px-4 md:px-0 flex flex-col gap-4 justify-center">
        <motion.div
          className="flex justify-between"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 100 }}
          transition={{ ease: "easeInOut", duration: 3 }}
        >
          {[
            "A",
            "R",
            "C",
            "H",
            "I",
            "T",
            "E",
            "C",
            "T",
            "U",
            "R",
            "A",
            "L",
          ].map((letter, i) => (
            <p
              key={i}
              className="text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl font-light bg-white/0 text-stone-500"
            >
              {letter}
            </p>
          ))}
        </motion.div>
        <motion.div
          className="flex justify-between"
          initial={{ x: 0, opacity: 0 }}
          animate={{ x: 0, opacity: 100 }}
          transition={{ ease: "easeInOut", duration: 3 }}
        >
          {[
            "P",
            "O",
            "R",
            "T",
            "F",
            "O",
            "L",
            "I",
            "O",
            " ",
            "2",
            "0",
            "2",
            "4",
          ].map((letter, i) => (
            <p
              key={i}
              className="text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl font-black bg-stone-700/0 text-stone-600"
            >
              {letter}
            </p>
          ))}
        </motion.div>
        <motion.p
          className="bg-stone-100 w-full py-2 text-md lg:text-2xl xl:text-xl 2xl:text-3xl text-center text-stone-400 font-thin tracking-wider"
          style={{
            maskImage:
              "linear-gradient(90deg, transparent, #000000 5%, #000000 95%, transparent)",
          }}
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 100 }}
          transition={{ ease: "easeInOut", duration: 3 }}
        >
          ALEJANDRO PEÑALVER MUNITA
        </motion.p>
      </div>

      <motion.div
        className="absolute bottom-12 flex items-center gap-3"
        initial={{ x: 0, opacity: 0 }}
        animate={{ x: 0, opacity: 100 }}
        transition={{ ease: "easeInOut", duration: 3 }}
      >
        <p className="opacity-50">Scroll down</p>
        <div className="relative w-7 h-12 border-2 border-black/30 rounded-full flex justify-center">
          <div className="absolute top-2.5 transform w-1.5 h-1.5 bg-black/50 rounded-full opacity-100 animate-wheel"></div>
        </div>
        <p className="opacity-50">to see more</p>
      </motion.div>
    </div>
  );
}

export default memo(Cover);
