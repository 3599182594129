import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { motion, useMotionValueEvent, useScroll } from "framer-motion";

import Progress from "../components/portfolio/Progress";
import Dock from "../components/portfolio/Dock";
import BookmarkGroup from "../components/portfolio/BookmarkGroup";

import Cover from "../components/portfolio/Cover";
import ContentPages from "../components/portfolio/ContentPages";
import BackCover from "../components/portfolio/BackCover";

import portfolioBookmarks from "../assets/pf-bookmarks.json";

function Portfolio() {
  // console.log("Portfolio rendered");
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { scrollYProgress, scrollY } = useScroll();
  const [section, setSection] = useState("none");
  const { key } = useParams();
  const diff = key === "arq" ? 0 : 1;

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useMotionValueEvent(scrollY, "change", (latest) => {
    const page = Math.floor(latest / windowHeight + 1.5) + diff;
    portfolioBookmarks.forEach((bookmark) => {
      if (page < 4 || page > 49) {
        return setSection("none");
      } else if (+bookmark.start === page || +bookmark.end === page) {
        return setSection(bookmark.id);
      }
    });
  });

  return (
    <>
      {/* PROGRESS */}
      <Progress scrollY={scrollYProgress} />

      {/* DOCK AND BOOKMARK */}
      {windowHeight > 650 && windowWidth > 640 && (
        <>
          <Dock section={section} />
          <BookmarkGroup />
        </>
      )}

      {/* CONTENT */}
      <motion.div className="sm:w-[calc(100%-128px)] mx-auto snap-y">
        <Cover />
        <ContentPages />
        <BackCover />
      </motion.div>
    </>
  );
}

export default Portfolio;
