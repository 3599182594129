import { memo } from "react";
import { useParams } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

import portfolioPages from "../../assets/pf-pages.json";
import portfolioPagesArq from "../../assets/pf-pages-arq.json";
import AboutLinks from "./XLinks";

function ContentPages() {
  const { key } = useParams();
  const pages = key === "arq" ? portfolioPagesArq : portfolioPages;

  return (
    <>
      {pages.map((data) => {
        return (
          <div key={data.page}>
            <div
              id={data?.id}
              className="h-screen flex justify-center snap-start bg-red-500/0"
            >
              <LazyLoadImage
                src={require(`../../img/portfolio/pages/pf_${data.page}.avif`)}
                className="object-contain"
              />
            </div>
            {data.page === "50" ? <AboutLinks /> : null}
          </div>
        );
      })}
    </>
  );
}

export default memo(ContentPages);
