import { memo } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import backCover from "../../img/portfolio/pages/back-cover.webp";

function BackCover() {
  return (
    <div className="h-screen w-screen sm:-translate-x-16 flex justify-center snap-start bg-black">
      <LazyLoadImage
        id="final-cover"
        src={backCover}
        alt="Architectural De Rotterdam Model"
        className="object-contain"
      />
      <p className="absolute bottom-0 right-0 p-4 text-white/50 text-sm">
        De Rotterdam model
      </p>
    </div>
  );
}

export default memo(BackCover);
