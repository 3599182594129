import { motion } from "framer-motion";
import { memo } from "react";

function Progress({ scrollY }) {
  return (
    <motion.div
      style={{
        scaleX: scrollY,
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        height: "3px",
        background: "dimgray",
        transformOrigin: "0%",
        zIndex: 100,
      }}
    />
  );
}

export default memo(Progress);
