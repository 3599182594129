import JavaScript from '../../img/logos/javascript.svg'
import TypeScript from '../../img/logos/typescript-icon.svg'
import HTML5 from '../../img/logos/html-5.svg'
import CSS3 from '../../img/logos/css-3.svg'
import React from '../../img/logos/react.svg'
import Redux from '../../img/logos/redux.svg'
import Angular from '../../img/logos/angular-icon.svg'
import NodeJS from '../../img/logos/nodejs-icon.svg'
import MongoDB from '../../img/logos/mongodb-icon.svg'
import PostgreSQL from '../../img/logos/postgresql.svg'
import Sequelize from '../../img/logos/sequelize.svg'
import Redis from '../../img/logos/redis.svg'
import GraphQL from '../../img/logos/graphql.svg'
import GIT from '../../img/logos/git-icon.svg'
import TailwindCSS from '../../img/logos/tailwindcss-icon.svg'
import Jest from '../../img/logos/jest.svg'
import Cypress from '../../img/logos/cypress-icon.svg'
import Express from '../../img/logos/express.svg'
import Koa from '../../img/logos/koa.svg'
import Vite from '../../img/logos/vite-logo.svg'

function Logos() {
    return (
        <>
            <div className="flex flex-wrap justify-center gap-6">
                <img src={JavaScript} title="JavaScript" className="h-12 w-12" />
                <img src={TypeScript} title="TypeScript" className="h-12 w-12" />
                <div className="relative h-12 w-12 overflow-hidden">
                    <img src={HTML5} title="HTML5" className="absolute scale-90 bottom-[-4px]" />
                </div>
                <div className="relative h-12 w-12 overflow-hidden">
                    <img src={CSS3} title="CSS3" className="absolute scale-90 bottom-[-4px]" />
                </div>
                <img src={React} title="React" className="h-12 w-12" />
                <img src={Vite} title="Vite" className="h-12 w-12" />
                {/* <img src={Redux} title="Redux" className="h-12 w-12" /> */}
                {/* <img src={Angular} title="Angular" className="h-12 w-12" /> */}
                <img src={NodeJS} title="NodeJS" className="h-12 w-12" />
                <img src={MongoDB} title="MongoDB" className="h-12 w-12" />
                <img src={PostgreSQL} title="PostgreSQL" className="h-12 w-12" />
                {/* <img src={Sequelize} title="Sequelize" className="h-12 w-12" /> */}
                {/* <img src={Redis} title="Redis" className="h-12 w-12" /> */}
                {/* <img src={GraphQL} title="GraphQL" className="h-12 w-12" /> */}
                <img src={GIT} title="GIT" className="h-12 w-12" />
                <img src={TailwindCSS} title="TailwindCSS" className="h-12 w-12" />
                <img src={Jest} title="Jest" className="h-12 w-12" />
                <img src={Cypress} title="Cypress" className="h-12 w-12" />
                <img src={Koa} title="Koa" className="h-9 self-center dark:invert transition-all ease-in-out duration-500" />
                <img src={Express} title="ExpressJS" className="h-8 self-center dark:invert mt-4 transition-all ease-in-out duration-500" />
            </div>
        </>
    );
}

export default Logos;
