import { memo } from "react";

import portfolioBookmarks from "../../assets/pf-bookmarks.json";
import portfolioBookmarksArq from "../../assets/pf-bookmarks-arq.json";

import { Bookmark } from "./Bookmark";
import { useParams } from "react-router-dom";

function BookmarkGroup() {
  const { key } = useParams();
  const bookmarks = key === "arq" ? portfolioBookmarksArq : portfolioBookmarks;

  return (
    <div className="absolute hidden sm:block right-0 z-50">
      {bookmarks.map((data, i) => (
        <Bookmark key={i} {...data} />
      ))}
    </div>
  );
}

export default memo(BookmarkGroup);
