import Email from "../../img/logos/email-black.png";
import GitHub from "../../img/logos/github-icon.svg";
import LinkedInBlack from "../../img/logos/linkedin-black.png";
import { RiArrowUpSLine } from "react-icons/ri";
import useSound from "use-sound";
import popSfx from "../../sfx/pop-sound.mp3";

function Contact() {
  const [play] = useSound(popSfx);

  return (
    <div
      id="contact"
      className="relative min-h-screen w-full flex justify-evenly items-center text-white dark:text-slate-300 pt-16 pb-20 lg:pt-0 lg:pb-0 border-t border-t-white/50"
      style={{
        backgroundColor: "#292524",
        opacity: "1",
        background:
          "radial-gradient(circle, transparent 20%, #292524 20%, #292524 80%, transparent 80%, transparent), radial-gradient(circle, transparent 20%, #292524 20%, #292524 80%, transparent 80%, transparent) 12.5px 12.5px, linear-gradient(#1c1917 1px, transparent 1px) 0 -0.5px, linear-gradient(90deg, #1c1917 1px, #292524 1px) -0.5px 0",
        backgroundSize: "25px 25px, 25px 25px, 12.5px 12.5px, 12.5px 12.5px",
      }}
    >
      <div
        className="absolute inset-0"
        style={{
          background:
            "linear-gradient(to right, rgba(0,0,0,0), rgba(28,25,23,1))",
        }}
      />
      <div className="absolute bottom-0">
        <a href="#intro">
          <RiArrowUpSLine className="h-12 w-12" onClick={() => play()} />
        </a>
      </div>

      <div className="flex flex-col w-4/5 sm:w-3/5 lg:w-2/5 z-10">
        <div className="flex flex-row justify-center items-center mb-8">
          <div className="flex flex-row shrink-0 gap-4">
            <a href="mailto:apenalvermunita@gmail.com" title="Send me an email">
              <img
                src={Email}
                alt="Email"
                className="w-8 h-8 invert opacity-80 hover:opacity-50"
              />
            </a>
            <a
              href="https://www.linkedin.com/in/alejandro-pemu/"
              title="Go to my LinkedIn"
              target="_blank"
            >
              <img
                src={LinkedInBlack}
                alt="LinkedIn"
                className="w-8 h-8 invert opacity-80 hover:opacity-50"
              />
            </a>
            <a
              href="https://github.com/alepemu"
              title="Go to my GitHub"
              target="_blank"
            >
              <img
                src={GitHub}
                alt="GitHub"
                className="w-8 h-8 invert hover:opacity-50"
              />
            </a>
          </div>
        </div>

        <div className="w-full h-2/5 p-8 border-2 border-slate-300 dark:border-white/50 rounded-xl shadow-2xl">
          <form
            action="https://formsubmit.co/geo.al3x@gmail.com"
            method="POST"
            className="flex flex-col"
          >
            <label htmlFor="user">Your name</label>
            <input
              type="text"
              name="user"
              placeholder="What is your name?"
              className="p-2 rounded text-slate-500 dark:bg-white/50 dark:placeholder:text-slate-900 dark:text-black transition-all ease-in-out duration-500"
            ></input>
            <label htmlFor="email" className="mt-4">
              Your email
            </label>
            <input
              type="email"
              name="email"
              placeholder="What is your email?"
              className="p-2 rounded text-slate-500  dark:bg-white/50 dark:placeholder:text-slate-900 dark:text-black transition-all ease-in-out duration-500"
            ></input>
            <label htmlFor="message" className="mt-4">
              Your message
            </label>
            <textarea
              rows="3"
              name="message"
              placeholder="What do you want to say?"
              className="p-2 rounded resize-none text-slate-500  dark:bg-white/50 dark:placeholder:text-slate-900 dark:text-black transition-all ease-in-out duration-500"
            ></textarea>
            <button
              type="submit"
              className="border border-white hover:bg-slate-500 p-2 rounded w-24 mt-4 transition-all ease-in-out duration-250"
            >
              SEND
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
