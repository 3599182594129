import { useState, useRef, useEffect } from "react";

function AboutMe() {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [screenWidth, setScreenWidth] = useState(getCurrentWidth());
  const meContainer = useRef(null);

  function getCurrentWidth() {
    return window.innerWidth;
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenWidth(getCurrentWidth());
    };
    window.addEventListener("resize", updateDimension);
  }, [screenWidth]);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    });
    observer.observe(meContainer.current);
    return () => observer.disconnect();
  }, [isIntersecting]);

  useEffect(() => {
    const logos = meContainer.current.querySelector("#me").classList;
    if (screenWidth >= 400) {
      isIntersecting
        ? logos.remove("opacity-0", "blur", "-translate-x-[150%]")
        : logos.add("opacity-0", "blur", "-translate-x-[150%]");
    }
  }, [isIntersecting]);

  return (
    <div ref={meContainer} className="w-4/5 lg:w-2/5">
      <div id="me" className="transition-all ease-in-out duration-500">
        <h2 className="font-bold text-2xl mb-4">ABOUT ME</h2>
        <p id="temp" className="mb-4">
          My name is Alejandro. I am a full stack developer based in Barcelona.
          Currently specialised in JavaScript technologies using mainly the MERN
          stack. NodeJS, Express, React, MongoDB, SQL, TypeScript, Vite,
          TailwindCSS...
        </p>
        <p>
          Passionate about new technologies and good design. This is reflected
          in my approach to software development. My strong attention to detail
          ensures that the end result is not only visually appealing but also
          highly functional and user-friendly.
        </p>
      </div>
    </div>
  );
}

export default AboutMe;
