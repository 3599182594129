import { FiExternalLink } from "react-icons/fi";
import logoRJA from "../../img/logos/logo-rja.jpg";

const logos = {
  logoRJA: logoRJA,
};

function WorkCard({ job, className }) {
  return (
    <div
      className={`mt-10 relative flex flex-col justify-between w-full max-w-[600px] h-auto bg-slate-300 p-4 pt-2 border-t-4 border-t-amber-400 rounded-b-xl shadow-2xl hover:shadow-[0_20px_25px_-5px_rgba(0,0,0,0.5)] transition-all duration-1000 dark:bg-amber-300/20 ${className} dark:shadow-[0_15px_30px_10px_rgba(0,0,0,0.8)]`}
    >
      {job.weblink === "" ? (
        ""
      ) : (
        <a
          className="absolute right-4 top-4 cursor-pointer"
          title="Website link"
          href={job.weblink}
          target="_blank"
        >
          <FiExternalLink className="dark:text-white" />
        </a>
      )}
      <h2 className="absolute -translate-x-4 -translate-y-9 text-sm text-white/75 font-thin uppercase ">
        {job.period}
      </h2>
      <div className="hidden md:block absolute -translate-x-20 -translate-y-3">
        <div className="w-20 h-1 bg-amber-400" />
        <div className="overflow-none flex w-14 h-14 rounded-full border-4 bg-[#05232f] border-amber-400 -translate-x-6 -translate-y-7">
          <img
            src={logos[job.logo]}
            alt="logo"
            className={`mx-auto my-auto w-full rounded-full`}
          />
        </div>
      </div>

      <div className="">
        <div className="flex flex-col md:flex-row items-start justify-start gap-0 md:gap-2">
          <h1 className="text-xl dark:text-white font-semibold">{job.title}</h1>
          <h2 className="flex gap-2 text-lg dark:text-white/50 font-thin">
            <span className="hidden md:block">- </span>
            {job.position}
          </h2>
        </div>
        <p className="dark:text-white text-sm">{job.description}</p>
        <ul className="dark:text-white text-sm list-disc ml-4 mt-2 space-y-1">
          {job.bulletPoints.map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ul>
        <div className="w-full flex justify-start mt-4 mb-0.5">
          <button className="text-black hover:before:bg-red relative flex items-center gap-2 py-1 px-4 rounded-lg  overflow-hidden bg-amber-400 transition-all before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0 before:h-full before:w-0 before:bg-black/25 before:transition-all before:duration-500 hover:before:left-0 hover:before:w-full hover:text-white">
            <a
              href="portfolio"
              target="_blank"
              className="flex items-center gap-2 relative z-10"
            >
              Go to portfolio
              <FiExternalLink />
            </a>
          </button>
        </div>
      </div>
    </div>
  );
}

export default WorkCard;
