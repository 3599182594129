import NavBar from "../components/home/Navbar";
import Intro from "../components/home/Intro";
import About from "../components/home/About";
import Work from "../components/home/Work";
import Contact from "../components/home/Contact";
import background from "../background.jpg";

function App() {
  return (
    <>
      <div className="dark">
        <img
          src={background}
          alt="background"
          className="absolute min-w-screen min-h-screen object-cover opacity-50 -z-10"
        />
        <div className="flex flex-col items-center bg-gradient-to-r from-[#05232f]/70 to-amber-950/70 transition-all ease-in-out duration-500 ">
          <NavBar />
          <Intro />
          <Work />
          <About />
          <Contact />
        </div>
      </div>
    </>
  );
}

export default App;
