import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./index.css";

import App from "./pages/App";
import Portfolio from "./pages/Portfolio";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/portfolio/:key?" element={<Portfolio />} />
      </Routes>
    </BrowserRouter>
  // </React.StrictMode>
);
