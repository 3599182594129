import Logos from "./Logos";
import { useState, useRef, useEffect } from "react";

function AboutLogos() {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [screenWidth, setScreenWidth] = useState(getCurrentWidth());
  const logosContainer = useRef(null);

  function getCurrentWidth() { return window.innerWidth }

  useEffect(() => {
    const updateDimension = () => { setScreenWidth(getCurrentWidth()) }
    window.addEventListener('resize', updateDimension);
  }, [screenWidth])

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    });
    observer.observe(logosContainer.current);
    return () => observer.disconnect();
  }, [isIntersecting]);

  useEffect(() => {
    const logos = logosContainer.current.querySelector('#logos').classList;
    if (screenWidth >= 400) {
      isIntersecting ? logos.remove("opacity-0", "blur") : logos.add("opacity-0", "blur");
    }
  }, [isIntersecting]);

  return (
    <div ref={logosContainer}>
      <div id="logos" className="w-4/5 flex flex-col mx-auto mt-8 lg:mt-0 transition-all ease-in-out duration-1000">
        <h2 className="font-bold text-2xl mb-8">TECH STACK</h2>
        <Logos />
      </div>
    </div>
  );
}

export default AboutLogos;
